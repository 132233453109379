<template>
  <v-navigation-drawer
    id="core-navigation-drawer"
    v-model="drawer"
    class="layout-dashboard"
    :expand-on-hover="expandOnHover"
    :right="$vuetify.rtl"
    mobile-breakpoint="960"
    app
    width="300"
    v-bind="$attrs"
  >
    <!-- <v-divider class="mb-1" /> -->

    <template v-slot:prepend>
      <v-expand-x-transition>
        <v-row
          :class="drawer ? 'brand-active-drawer' : 'brand-inactive-drawer'"
        >
          <v-col class="ma-0 pa-0">
            <img src="@/assets/logo_vital_checkups.svg" />
          </v-col>
        </v-row>
      </v-expand-x-transition>
    </template>

    <v-list
      nav
      dense
      :class="!drawer ? 'layout-sider-inactive-drawer' : 'layout-sider'"
    >
      <v-list-item
        :to="item.path"
        :class="drawerClass(item)"
        v-for="item in items"
        :key="item.title"
        link
      >
        <v-list-item-icon>
          <img :src="item.icon" :class="iconClass(item)" height="28px" />
        </v-list-item-icon>

        <v-list-item-content>
          <span :class="titleClass(item)">{{ item.title }}</span>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <template v-slot:append>
      <div class="pa-2 pt-5 signout" @click="logout()">
        <!-- <v-icon class="icon-deactive">mdi-gavel</v-icon> -->
        <v-row>
          <v-col class="pl-8" cols="2">
            <img :src="sign_out_black" height="24px">
          </v-col>
          <v-col>
            <span style="font-size: 18px !important; font-weight: 400">Sign Out</span>
          </v-col>
        </v-row>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import {
  dashboard_black,
  dashboard_white,
  medical_team_black,
  patients_black,
  patients_white,
  medical_team_white,
  hospital_white,
  hospital_black,
  sign_out_black
} from "@/assets";

const { LOGO } = require(`@/assets/logo_vital_checkups.svg`);

export default {
  name: "DashboardCoreDrawer",
  components: {},
  props: {
    expandOnHover: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    expand_reports: false,
    theme: process.env.VUE_APP_THEME,
    appVersion: process.env.VUE_APP_VERSION,
    sign_out_black: sign_out_black,
    logo: LOGO,
    emergencyDialog: false,
    webrtcErrorDialog: false,
    onLine: null,
    internetStatus: "Connected",
    mini: true,
  }),

  computed: {
    ...mapState(["barColor", "barImage", "webrtcErrors"]),
    ...mapGetters({
      roleName: "authentication/getRole",
    }),
    drawer: {
      get() {
        return this.$store.state.drawer;
      },
      set(val) {
        this.$store.commit("SET_DRAWER", val);
      },
    },
    items() {
      switch (this.roleName) {
        case "HOSPITAL_ADMIN":
          return [
            {
              title: "Staff",
              icon:
                "medicalstaff" ===
                this.$route.name.split(" ").join("").toLowerCase()
                  ? medical_team_white
                  : medical_team_black,
              path: "/medicalstaff",
            },
            {
              title: "Patients",
              icon:
                "patients" ===
                this.$route.name.split(" ").join("").toLowerCase()
                  ? patients_white
                  : patients_black,
              path: "/patients",
            },
            {
              title: "Medical Team",
              icon:
                "medicalteam" ===
                this.$route.name.split(" ").join("").toLowerCase()
                  ? medical_team_white
                  : medical_team_black,
              path: "/medicalteam",
            },
          ];
        case "SYSTEM_ADMIN":
          return [
            {
              title: "Medical Organizations",
              icon:
                "medicalorganizations" ===
                this.$route.name.split(" ").join("").toLowerCase()
                  ? hospital_white
                  : hospital_black,
              path: "/medicalorganizations",
            },
            {
              title: "Hospitals",
              icon:
                "hospitals" ===
                this.$route.name.split(" ").join("").toLowerCase()
                  ? hospital_white
                  : hospital_black,
              path: "/hospitals",
            },
            {
              title: "Admins",
              icon:
                "admins" ===
                this.$route.name.split(" ").join("").toLowerCase()
                  ? patients_white
                  : patients_black,
              path: "/admins",
            },
          ];
        default:
          return [
            {
              title: "Dashboard",
              icon:
                "dashboard" ===
                this.$route.name.split(" ").join("").toLowerCase()
                  ? dashboard_white
                  : dashboard_black,
              path: "/dashboard",
            },
            {
              title: "Medical Team",
              icon:
                "medicalteam" ===
                this.$route.name.split(" ").join("").toLowerCase()
                  ? medical_team_white
                  : medical_team_black,
              path: "/medicalteam",
            },
            {
              title: "Patients",
              icon:
                "patients" ===
                this.$route.name.split(" ").join("").toLowerCase()
                  ? patients_white
                  : patients_black,
              path: "/patients",
            },
          ];
      }
    },
  },
  methods: {
    logout() {
      this.$router.push({ name: "Login" });
    },
    drawerClass(item) {
      if (item.path === "/dashboard") {
        return item.path.substring(1) === this.$route.name.toLowerCase()
          ? "layout-sider-item-active-dashboard"
          : "layout-sider-item-deactive";
      } else
        return item.path.substring(1) ===
          this.$route.name.split(" ").join("").toLowerCase()
          ? "layout-sider-item-active"
          : "layout-sider-item-deactive";
    },
    iconClass(item) {
      const icon_class =
        item.path.substring(1) ===
        this.$route.name.split(" ").join("").toLowerCase()
          ? "icon-active"
          : "icon-deactive";
      return icon_class;
    },
    titleClass(item) {
      return item.path.substring(1) ===
        this.$route.name.split(" ").join("").toLowerCase()
        ? "primary--text"
        : "main_black--text";
    },
  },
};
</script>

